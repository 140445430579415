
























import { Component, Vue } from "vue-property-decorator";
import MyList from "@/components/MyList.vue";
import Mixin from "@/js/mixins";

@Component({ components: { MyList }, mixins: [Mixin] })
export default class ShopBill extends Vue {
  public user_id = "";
  public list: any[] = []; // 数据的列表
  public refresh = 0; // 页面允许刷新（为了list的改变）
  public type = '';
  public i = '';
  public url = '';

  clearList() {
    this.list = [];
  }
  setList(list: any[]) {
    this.list.push(...list);
  }
  nav(item){
    if(item.is_order == "1"){
      this.$router.push('./billdetail?id='+item.log_id+'&user_id='+this.user_id)
    }
  }
  init() {
    this.user_id = String(this.$route.query.user_id || "");
    this.type = String(this.$route.query.type || "");
    this.i = String(this.$route.query.i || "");
    if(this.i == "1"){
      this.url = "balance/user/branch-balance-log"
    }else{
      this.url = "balance/user/balance-log"
    }
    this.list = [];
    this.refresh++;
  }
}
